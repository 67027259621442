<template>
  <v-container id="user-settings" fluid tag="section">
    <alert-notification :message="alert" />
    <v-row>
      <v-col cols="12" sm="6">
        <v-select v-model="settings.vehicle.value" :items="vehicles.filter((vehicle) => !settings.vehicle.value.startsWith('training') || vehicle.id.startsWith('training'))"
                  :label="$t('userSettings.settings.vehicle.name')" :loading="loading" :disabled="loading"
                  item-value="id" item-text="name" mandatory filled color="primary" @change="updateData"
        />
        <label>
          {{ $t('userSettings.settings.vehicle.values.' + settings.vehicle.value + '.description') }}
        </label>
      </v-col>
    </v-row>
    <!--    <v-row>-->
    <!--      <v-col cols="12" sm="6">-->
    <!--        <v-btn color="primary" @click.stop="enableNotification">-->
    <!--          {{ $t('userSettings.settings.notification.name') }}-->
    <!--        </v-btn>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
  </v-container>
</template>

<script>
  import { Logger } from '@/services/common/Logger';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import { notification } from '@/util/DeviceUtil';

  export default {
    name: 'UserSettings',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        settings: {
          vehicle: {
            id: undefined,
            name: 'vehicle',
            value: 'motorcycle'
          }
        },
        vehicles: [
          { id: 'trainingDistance', name: this.$t('userSettings.settings.vehicle.values.trainingDistance.name') },
          { id: 'training', name: this.$t('userSettings.settings.vehicle.values.training.name') },
          { id: 'trainingDistance2', name: this.$t('userSettings.settings.vehicle.values.trainingDistance2.name') },
          { id: 'training2', name: this.$t('userSettings.settings.vehicle.values.training2.name') },
          { id: 'bike', name: this.$t('userSettings.settings.vehicle.values.bike.name') },
          { id: 'scooter', name: this.$t('userSettings.settings.vehicle.values.scooter.name') },
          { id: 'lightmotorcycle', name: this.$t('userSettings.settings.vehicle.values.lightmotorcycle.name') },
          { id: 'motorcycle', name: this.$t('userSettings.settings.vehicle.values.motorcycle.name') },
          { id: 'car', name: this.$t('userSettings.settings.vehicle.values.car.name') }
        ]
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      enableNotification () {
        notification(this.$t('userSettings.settings.notification.validation.title'),
                     this.$t('userSettings.settings.notification.validation.description'), () => {}, Logger.exception);
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'user/settings');
          if (response.data !== undefined && response.data.settings !== undefined) {
            response.data.settings.forEach((setting) => {
              switch (setting.name) {
                case 'vehicle':
                  this.settings.vehicle.id = setting.id;
                  this.settings.vehicle.name = setting.name;
                  this.settings.vehicle.value = setting.value;
                  break;
              }
            });
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('userSettings.error.fail'));
        }
        this.loading = false;
      },
      async updateData () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'user/settings', {
            settings: [
              { id: this.settings.vehicle.id, name: this.settings.vehicle.name, value: this.settings.vehicle.value }
            ]
          });
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('userSettings.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>
